import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard, LoginGuard } from './core/services'

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadComponent: () => import('./core/index').then(com => com.LoginComponent),
    canActivate:[LoginGuard]
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./core/index').then(com => com.MenuViewComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadComponent: () => import('./components/feature/index').then(com => com.SettingsComponent),
    canActivate: [AuthGuard],
  },

]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
