import {Component, OnInit} from '@angular/core'
import { LoadingService } from './core/components/loading/loading.service'
import {initFlowbite} from "flowbite";
// interface navbarToggle {
//   screenWidth: number
//   collapsed: boolean
// }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private loadingService: LoadingService,){}

  ngOnInit() {
    initFlowbite();

    this.loadingService.isLoading.subscribe((isLoading) => {
      this.loading = isLoading
    })
  }
  // title = 'maxim-user-ui'
  // isNavBarCollapsed = false
  // screenWidth = 0

  // onToggleNavBar(data: navbarToggle): void {

  //   this.screenWidth = data.screenWidth
  //   this.isNavBarCollapsed = data.collapsed
  // }

  loading: boolean = false // Add the loading property
}
